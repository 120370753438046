///////////////////////////////////
/// AGLO Design System
///////////////////////////////////
@import './variables.scss';
/* Custom designs */
@import 'assets/css-components/chips.css';
@import 'assets/css-components/buttons.css';
@import 'assets/css-components/cards.css';
// @import 'assets/css-components/container.css';
@import 'assets/css-components/search_engine.css';
@import 'assets/css-components/form.css';
@import 'assets/css-components/category_filter.css';
////////////////
/// Colors
////////////////
/// primary
$color-primary: #0A1747;
$color-primary-focus: #1F306E;
$color-primary-disabled: #E1E4EE;
$color-primary-disabled-txt: #B6BACB;
$color-primary-text: #FFFFFF;
/// Secondary
$color-secondary-focus: #DFE4F6;
$color-secondary-disabled-txt: #B6BACB;
/// Others
$cadet-blue:#B6BACB;

///
$wild-blue-yonder: #7C8AB8;

/// Background
.main-page-bg{
  background-color: $page-background;
}
.main-page-white-lilac{
  background-color: $page-background-white-lilac;
}
.silver-arthens-grey-bg{
  background-color: $silver-arthens-gray;
}
.downriver-bg{
  background-color: $downriver;
}
.bg-dark-pink{
  background-color: $color-dark-pink;
}

.no-border-focus{
  &:focus{
    border-bottom: none !important;
    box-shadow: none !important;
  }
}

////////////////
/// Typoghraphy
////////////////
.ag-heading-h6{
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: $downriver;
}
.ag-heading-h5{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: $downriver;
}
.ag-heading-h4{
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: $downriver;
}
.ag-heading-downriver-h3{
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  color: $downriver;
}
.ag-heading-h3{
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 24px;
  color: $blue-color;
}
.ag-heading-h2{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: $downriver;
}
.ag-heading-h1{
  font-family: 'Lato';
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 29px;
  color: $downriver;
}
.ag-para-1-sm{
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  color: $downriver;
  margin-bottom: 0px;
}
.ag-para-1{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $downriver;
  margin-bottom: 0px;
}
.ag-para-1-primary{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $blue-color;
  margin-bottom: 0px;
}
.ag-para-1-downriver{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $downriver;
  margin-bottom: 0px;
}
.ag-para-2{
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: $downriver;
  margin-bottom: 0px;
}
.ag-para-3{
  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  color: $downriver;
  margin-bottom: 0px;
}
.ag-para-4{
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: $downriver;
  margin-bottom: 0px;
}
.ag-bold{
  font-weight: bold;
}
.ag-weight-400{
  font-weight: 400;
}
.ag-weight-500{
  font-weight: 500;
}
.ag-weight-600{
  font-weight: 600;
}
.ag-weight-700{
  font-weight: 700;
}
.ag-weight-900{
  font-weight: 900;
}
.ag-italic{
  font-style: italic;
}
.ag-normal-font-style{
  font-style: normal;
}
////////////////
/// Text
////////////////
.ag-text-danger{
  color: #F21D2F;
  font-size: 12px;
}
.ag-text-underline{
  text-decoration: underline;
}
.ag-text-success-green{
  color: $success-green;
}
.ag-text-dark-green{
  color: $dark-green;
}
.ag-text-success-tertiary{
  color: $color-tertiary;
}
.ag-text-cadet-blue{
  color:$cadet-blue;
}
.ag-text-down-river{
  color:$downriver;
}
.ag-text-black{
  color: #000000;
}
.ag-text-grey{
  color: rgba(0, 0, 0, 0.5);
}
.ag-text-blue{
  color: $blue-color;
}
.ag-text-link-blue{
  color: $solid-blue;
}
.ag-silver-border{
  border: solid 1px $silver-iron-color;
}

.ag-text-12{
  font-size: 12px;
}
.ag-text-16{
  font-size: 16px;
}
.ag-text-17{
  font-size: 17px;
}
.ag-text-18{
  font-size: 18px;
}
.ag-text-20{
  font-size: 20px;
}
.ag-text-24{
  font-size: 24px;
}
.ag-text-34{
  font-size: 34px;
}

.ag-text-bold{
  font-weight: bold;
}
.ag-text-regular{
  font-weight: normal;
}
.ag-text-white{
  color: #FFFFFF;
  &:visited{
    color: #FFFFFF;
  }
}
.color-3805F2{
  color:#3805F2;
}
////////////////////////////////
/// 01 - Buttons
////////////////////////////////
$primary-btn-padding: 16px 24px 15px 24px;

$primary-btn-padding-vertical: 15px;
$primary-btn-padding-horizontal: 24px;

$primary-btn-sm-padding: 9px 24px 10px 24px;
$primary-btn-xs-padding: 6px 10px 7px 10px;

$primary-btn-border-radius: 6px;
/// Icon
$btn-icon-margin-right: 5px;
$btn-icon-size: 17px;



////////////////
/// Mixing
////////////////
@mixin btnsSharedMixing(){
  padding: 0px;
  outline: none;
  color: $color-primary-text;
  border-radius: $primary-btn-border-radius;
  transition: all 0.3s linear;
  font-size: 14px;
  i{
    font-size: $btn-icon-size;
    margin-right: $btn-icon-margin-right;
  }
}
@mixin primaryBtnMixing(){
  @include btnsSharedMixing();
  background-color: $color-primary;
  border: none;
  /// Hover
  &:hover{
    box-shadow: -2px 3px 6px #0A174733;
  }
  /// Focus
  &:focus{
    background-color: $color-primary-focus;
  }
  /// Disabled
  &:disabled{
    background-color: $color-primary-disabled;
    color: $color-secondary-disabled-txt;
  }
}
@mixin secondaryBtnMixing(){
  @include btnsSharedMixing();
  background-color: transparent;
  border: solid 1px $color-primary;
  color: $color-primary;
  /// Hover
  &:hover{
    box-shadow: -2px 3px 6px #0A174733;
  }
  /// Focus
  &:focus{
    background-color: $color-secondary-focus;
  }
  /// Disabled
  &:disabled{
    border: solid 1px $color-secondary-disabled-txt;
    color: $color-primary-disabled-txt;
  }
}
@mixin tertiaryBtnMixing(){
  @include btnsSharedMixing();
  background-color: transparent;
  border: transparent;
  color: $color-primary;
  /// Hover
  &:hover{
    box-shadow: -2px 3px 6px #0A174733;
  }
  /// Focus
  &:focus{
    background-color: $color-secondary-focus;
  }
  /// Disabled
  &:disabled{
    color: $color-primary-disabled-txt;
    border-color: $color-primary-disabled-txt;
    box-shadow: none;
  }
}

/// Primary blue button
.ag-btn-blue{
  @include primaryBtnMixing();
  padding: 10px;
  background-color: $blue-color;
}

/// Primary Btn
.ag-btn-primary{
  @include primaryBtnMixing();
  padding: $primary-btn-padding;
}
/// Primary Btn small
.ag-btn-primary-sm{
  @include primaryBtnMixing();
  padding: $primary-btn-sm-padding;
}
/// Primary Btn Extra small
.ag-btn-primary-xs{
  @include primaryBtnMixing();
  padding: $primary-btn-xs-padding;
}

// Danger Btn Extra Small
.ag-btn-danger-bordered{
  @include btnsSharedMixing();
  padding: 5px 24px;
  background-color: transparent;
  border: solid 1px $alert-danger-text-color;
  color: $alert-danger-text-color;
  outline: none;
}
.ag-btn-danger-sm{
  @include primaryBtnMixing();
  padding: $primary-btn-sm-padding;
  background-color: $alert-danger-text-color;
}
// Danger Btn Extra Small
.ag-btn-danger-xs{
  @include primaryBtnMixing();
  padding: $primary-btn-xs-padding;
  background-color: $alert-danger-text-color;
}

/// Dark green Button
.ag-btn-dark-green{
  @include primaryBtnMixing();
  padding: 5px 24px;
  background-color: $dark-green;
  color: #FFFFFF;
  outline: none;
  &:focus{
    background-color: $dark-green;
  }
}

/// Secondary Btn
.ag-btn-secondary{
  @include secondaryBtnMixing();
  padding: $primary-btn-padding;
}
/// Secondary Btn small
.ag-btn-secondary-sm{
  @include secondaryBtnMixing();
  padding: $primary-btn-sm-padding;
}
/// Secondary Btn small
.ag-btn-secondary-sm{
  @include secondaryBtnMixing();
  padding: $primary-btn-sm-padding;
}
/// Secondary Btn Extra small
.ag-btn-secondary-xs{
  @include secondaryBtnMixing();
  padding: $primary-btn-xs-padding;
}
/// Secondary Btn Extra small
.ag-btn-grey{
  @include primaryBtnMixing();
  padding: $primary-btn-sm-padding;
  background-color: #F5F7FB;
  color: #BABABA;
}
/// tertiary Btn
.ag-btn-tertiary{
  @include tertiaryBtnMixing();
  padding: $primary-btn-padding;
}
/// tertiary Btn small
.ag-btn-tertiary-sm{
  @include tertiaryBtnMixing();
  padding: $primary-btn-sm-padding;
}
// borderd button
.ag-btn-bordered-sm{
  @include tertiaryBtnMixing();
  padding: $primary-btn-sm-padding;
  border: solid 1px $color-primary;
}
.ag-btn-bordered-xs{
  @include tertiaryBtnMixing();
  padding: $primary-btn-xs-padding;
  border: solid 1px $color-primary;
}
.ag-btn-no-bg{
  background-color: transparent;
  outline: none;
  padding: $primary-btn-sm-padding;
  border: none;
  color: $blue-color;
}

// Toggle Button
$switch-width: 51px;
$switch-height: 24px;
$switch-slider-width: 20px;
$switch-slider-height: 20px;
$switch-checked-right-position: 24px;

.ag-switch {
  position: relative;
  display: inline-block;
  width: $switch-width;
  height: $switch-height;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked {
  		+ {
  			.slider {
  				background-color: $downriver;
          &:before {
  					-webkit-transform: translateX($switch-checked-right-position);
  					-ms-transform: translateX($switch-checked-right-position);
  					transform: translateX($switch-checked-right-position);
  				}
  			}
  		}
  	}
    &:focus {
  		+ {
  			.slider {
  				box-shadow: 0 0 1px $downriver;
  			}
  		}
  	}
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $silver-iron-color;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: $switch-slider-height;
      width: $switch-slider-width;
      left: 4px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
  }
  /* Rounded sliders */
  .slider.round {
    border-radius: $switch-height;
    &:before {
  		border-radius: 50%;
  	}
  }
}


////////////////////////////////
/// 02 - Tooltip, Notifications
////////////////////////////////
.ag-tooltip-1{
  background-color: $downriver;
  // width: 75px;
  height: fit-content;
  border-radius: 2px;
  color: #FFFFFF;
  font-size: 12px;
  font-weight: 700;
  border-radius: 8px;
  padding: 3px 6px;
  position: absolute;
  display: none;
  &:after{
    content: "";
    position: absolute;
    display: block;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    background-color: $downriver;
    left: calc(50% - 8px);
    bottom: -2px;
  }
}


////////////////////////////////
///  Width & Height
////////////////////////////////
.full-width{
  width: 100%;
}
.half-width{
  width: 50%;
}
.width-5{
  width: 5%;
}
.width-10{
  width: 10%;
}
.width-15{
  width: 15%;
}
.width-20{
  width: 20%;
}
.width-21{
  width: 21%;
}
.width-23{
  width: 23%;
}
.width-25{
  width: 25%;
}
.width-30{
  width: 30%;
}
.width-32{
  width: 32%;
}
.width-35{
  width: 35%;
}
.width-40{
  width: 40%;
}
.width-45{
  width: 45%;
}
.width-47{
  width: 47%;
}
.width-50{
  width: 50%;
}
.width-55{
  width: 55%;
}
.width-60{
  width: 60%;
}
.width-65{
  width: 65%;
}
.width-70{
  width: 70%;
}
.width-80{
  width: 80%;
}
.width-100{
  width: 100%;
}

.width-fit-content{width:fit-content;}
.height-100{
  height: 100%;
}

.width-65-p{
  width: 65px;
}


////////////////////////////////
///  margins & Paddings
////////////////////////////////
////////////////////////////////////
/// Margin
////////////////////////////////////
.no-margin{
  margin: 0px;
}
/// Right
.no-margin-right{margin-right: 0px !important;}
.margin-right-5{margin-right: 5px;}.margin-right-6{margin-right: 6px;}
.margin-right-8{margin-right: 8px;}.margin-right-10{margin-right: 10px;}.margin-right-12{margin-right: 12px;}
.margin-right-15{margin-right: 15px;}.margin-right-16{margin-right: 16px;}
.margin-right-17{margin-right: 17px;}.margin-right-18{margin-right: 18px;}
.margin-right-20{margin-right: 20px;}.margin-right-24{margin-right: 20px;}
.margin-right-11{margin-right: 11px;}.margin-right-12{margin-right: 12px;}
.margin-right-27{margin-right: 27px;}
.margin-right-30{margin-right: 30px;}.margin-right-32{margin-right: 32px;}
.margin-right-40{margin-right: 40px;}
.margin-right-100{margin-right: 100px;}
.margin-right-1p{margin-right: 1%;}.margin-right-2p{margin-right: 2%;}
/// Left
.margin-left-0{margin-left: 0px;}.margin-left-4{margin-left: 4px;}
.margin-left-5{margin-left: 5px;}.margin-left-8{margin-left: 8px;}
.margin-left-9{margin-left: 9px;}.margin-left-10{margin-left: 10px;}
.margin-left-12{margin-left: 12px;}.margin-left-15{margin-left: 15px;}.margin-left-16{margin-left: 16px;}
.margin-left-20{margin-left: 20px;}.margin-left-24{margin-left: 24px;}.margin-left-32{margin-left: 32px;}
.margin-left-47{margin-left: 47px;}.margin-left-48{margin-left: 48px;}
/// Bottom
.margin-bottom-4{margin-bottom: 4px;}
.margin-bottom-6{margin-bottom: 6px;}.margin-bottom-7{margin-bottom: 7px;}
.margin-bottom-8{margin-bottom: 8px;}.margin-bottom-9{margin-bottom: 9px;}.margin-bottom-10{margin-bottom: 10px;}
.margin-bottom-12{margin-bottom: 12px;}
.no-margin-bottom{margin-bottom: 0px;}.margin-bottom-5{margin-bottom: 5px;}
.margin-bottom-14{margin-bottom: 14px;}.margin-bottom-15{margin-bottom: 15px;}
.margin-bottom-16{margin-bottom: 16px;}.margin-bottom-17{margin-bottom: 17px;}
.margin-bottom-18{margin-bottom: 18px;}.margin-bottom-19{margin-bottom: 19px;}
.margin-bottom-20{margin-bottom: 20px;}.margin-bottom-23{margin-bottom: 23px;}
.margin-bottom-24{margin-bottom: 24px;}.margin-bottom-25{margin-bottom: 25px;}
.margin-bottom-27{margin-bottom: 27px;}.margin-bottom-30{margin-bottom: 30px;}
.margin-bottom-32{margin-bottom: 32px;}.margin-bottom-35{margin-bottom: 35px;}
.margin-bottom-40{margin-bottom: 40px;}.margin-bottom-44{margin-bottom: 44px;}
.margin-bottom-59{margin-bottom: 59px;}
.margin-bottom-40{margin-bottom: 40px;}.margin-bottom-44{margin-bottom: 44px;}
.margin-bottom-55{margin-bottom: 55px;}.margin-bottom-59{margin-bottom: 59px;}
.margin-bottom-70{margin-bottom: 70px;}
/// Top
.no-margin-top{margin-top: 0px;}
.margin-top-0{margin-top: 0px;}.margin-top-4{margin-top: 4px;}
.margin-top-5{margin-top: 5px;}.margin-top-6{margin-top: 6px;}
.margin-top-8{margin-top: 8px;}.margin-top-10{margin-top: 10px;}
.margin-top-15{margin-top: 15px;}.margin-top-16{margin-top: 16px;}
.margin-top-18{margin-top: 18px;}.margin-top-20{margin-top: 20px;}
.margin-top-24{margin-top: 24px;}.margin-top-25{margin-top: 25px;}
.margin-top-30{margin-top: 30px;}.margin-top-12{margin-top: 12px;}
.margin-top-32{margin-top: 32px;}.margin-top-38{margin-top: 38px;}
.margin-top-40{margin-top: 40px;}.margin-top-44{margin-top: 44px;}
.margin-top-49{margin-top: 49px;}.margin-top-53{margin-top: 53px;}
.margin-top-60{margin-top: 60px;}.margin-top-70{margin-top: 70px;}
.margin-top-80{margin-top: 80px;}.margin-top-100{margin-top: 100px;}
/// Vertical
.margin-vertical-8{margin-top: 8px;margin-bottom: 8px;}
.margin-vertical-10{margin-top: 10px;margin-bottom: 10px;}
.margin-vertical-15{margin-top: 15px;margin-bottom: 15px;}
.margin-vertical-40{margin-top: 40px;margin-bottom: 40px;}


////////////////////////////////////
/// Padding
////////////////////////////////////
.no-padding{
  padding: 0px;
}
.no-padding-left{
  padding-left: 0px;
}
.no-padding-right{
  padding-right: 0px;
}
.no-padding-top{
  padding-top: 0px;
}
.no-padding-top{
  padding-top: 0px;
}
.no-padding-bottom{
  padding-bottom: 0px;
}
.no-padding-horizontal{
  padding-left: 0px;
  padding-right: 0px;
}
/// All
.padding-3{padding: 3px;}
.padding-10{padding:10px;}.padding-12{padding: 12px;}.padding-15{padding:15px;}.padding-16{padding:16px;}
.padding-24{padding: 24px;}.padding-30{padding: 30px;}
/// Top
.padding-top-5{padding-top: 5px;}.padding-top-6{padding-top: 6px;}
.padding-top-10{padding-top: 10px;}.padding-top-12{padding-top: 12px;}
.padding-top-15{padding-top: 15px;}.padding-top-20{padding-top: 20px;}
.padding-top-32{padding-top: 32px;}.padding-top-45{padding-top: 45px;}
/// Bottom
.padding-bottom-4{padding-bottom: 4px;}.padding-bottom-6{padding-bottom: 6px;}
.padding-bottom-8{padding-bottom: 8px;}.padding-bottom-10{padding-bottom:10px;}
.padding-bottom-13{padding-bottom: 13px;}.padding-bottom-16{padding-bottom: 16px;}
.padding-bottom-32{padding-bottom: 32px;}.padding-bottom-23{padding-bottom: 23px;}
.padding-bottom-20{padding-bottom:20px;}
/// right
.padding-right-5{padding-right: 5px;}.padding-right-8{padding-right: 8px;}.padding-right-10{padding-right: 10px;}.padding-right-15{padding-right: 15px;}.padding-right-16{padding-right: 16px;}
.padding-right-20{padding-right: 20px;}.padding-right-6{padding-right: 6px;}.padding-right-12{padding-right: 12px;}
/// left
.padding-left-5{padding-left: 5px;}.padding-left-8{padding-left: 8px;}.padding-left-10{padding-left: 10px;}
.padding-left-12{padding-left: 12px;}.padding-left-15{padding-left: 15px;}.padding-left-20{padding-left: 20px;}
.padding-left-35{padding-left: 35px;}.padding-left-50{padding-left: 50px;}

////////////////////////////////////
/// Borders
////////////////////////////////////
.no-border-bottom{
  border-bottom: none;
}
.no-border-top{
  border-top: none;
}
.no-border{
  border: none;
}

////////////////////////////////////
/// Postions
////////////////////////////////////
.top-10{
  position: relative;
  top: 10px;
}

////////////////////////////////
///  FlexBox & Alignement
////////////////////////////////
.vertical-items{
  display: flex;
  flex-direction: column;
}
.horizontal-items{
  display: flex;
  flex-direction: row;
}
/// Alignement
.text-center{
  text-align: center;
}
.align-right{
  text-align: right;
}
.justify-center{
  justify-content: center;
}
.center-items{
  align-items: center;
}
.baseline-items{
  align-items: baseline;
}
.space-between{
  justify-content: space-between;
}
.flex-middle{
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-vertical{
  display: flex;
  flex-direction: column;
  // justify-content: center;
}
.justify-center{
  justify-content: center;
}
.justify-right{
  justify-content: right;
}
.flex-horizontal{
  display: flex;
  flex-direction: row;
  // justify-content: center;
}
.flex-right{
  display: flex;
  justify-content: right;
  align-items: center;
}
.flex-wrap{
  flex-wrap: wrap;
}
.flex{
  display: flex;
}
.flex-grow-1{
  flex-grow: 1;
}
.float-right{
  position:relative;
  float:right;
  margin-left:auto;
}
////////////////////////////////
///  Scrollbar
////////////////////////////////
.scroller-1{
  overflow-x: scroll;
}
.scroller-1::-webkit-scrollbar {
  background-color: #FFFFFF;
  width: 6px;
  border: #989898;
}
.scroller-1::-webkit-scrollbar-track {
}
.scroller-1::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 7px;
}
.scroller-1-y{
  overflow-y: scroll;
}
.scroller-1-y::-webkit-scrollbar {
  background-color: #FFFFFF;
  width: 6px;
  border: #989898;
}
.scroller-1-y::-webkit-scrollbar-track {
}
.scroller-1-y::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 7px;
}

.scroller-2-y{
  overflow-y: scroll;
}
.scroller-2-y::-webkit-scrollbar {
  background-color: #FFFFFF;
  width: 5px;
  border: #989898;
}
.scroller-2-y::-webkit-scrollbar-track {
}
.scroller-2-y::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 7px;
}
.scroller-2-x{
  overflow-x: overlay;
}
.scroller-2-x::-webkit-scrollbar {
  background-color: #FFFFFF;
  height: 4px;
  border: #989898;
}
.scroller-2-x::-webkit-scrollbar-track {
}
.scroller-2-x::-webkit-scrollbar-thumb {
  background-color: #d8d8d8;
  border-radius: 7px;
}



////////////////////////////////
///  Form & inputs
////////////////////////////////
.std-input{
  border: solid 1px $wild-blue-yonder !important;
  border-radius: 8px !important;
  height: 36px;
  padding-left: 12px;
  outline: none;
  &:focus{
    border-bottom: solid 1px $wild-blue-yonder !important;
  }
}
.input-aglo[type=checkbox]{
  width: 16px;
  height: 16px;
  margin-top: .25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid gray;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  outline: none !important;
  border-radius: 4px;
  &:checked{
    background-color: #3805F2 !important;
    border-color: #3805F2 !important;
    background-image : url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    outline: none !important;
  }
  &:disabled{
    border-color: rgba(0,0,0,.25) !important;
    &:checked{
      background-color: gray !important;
      border-color: gray !important;
      background-image : url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }
  }

}
.input-aglo[type=radio] {
  width: 1em;
  height: 1em;
  margin-top: .25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0,0,0,.25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  outline: none !important;
  border-radius: 50%;
  &:checked{
    background-color: #fff !important;
    // border-color: #fff !important;
    outline: none !important;
    border: solid 1px #3805F2;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%233805F2'/%3e%3c/svg%3e");
  }
}


/// Select Options
.select-style-1{
  padding: 10px 10px 10px 4px;
  width: 60%;
  background-color: #FFFFFF;
  border: solid 1px #EDEDED;
  border-radius: 2px;
  outline: none;
  option{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}

.ag-select-1{
  background-color: #FFFFFF;
  border: solid 1px #EDEDED;
  padding: 4px 8px 4px 8px;
  outline: none;
  option{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}
.ag-select-2{
  background: #FFFFFF;
  border: 1px solid $silver-iron-color;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 9px 15px 9px 12px;
  padding-right: 15px;
  outline: none;
  // left: 12;
  // right: 15;
  // up & down: 9;
  option{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
  }
}

input.ag-input-1{
  background-color: #FFFFFF;
  border: solid 1px #EDEDED;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  padding: 4px 8px 4px 8px;
  border-radius: 2px;
  width: auto;
}


////////////////////////////////
///  Display
////////////////////////////////
.hidden-item{
  display: none;
}
.block-item{
  display: block;
}
.inline-item{
  display: inline;
}
.inline-block-item{
  display: inline-block;
}
.relative{
  position: relative;
}
.cursor{
  cursor: pointer;
}
////////////////////////////////
///  Cards
////////////////////////////////
.card-style-1{
  border-radius: 5px;
  // background-color: ;
  box-shadow: 0px 4px 4px 0px #00000040;
}
.card-style-2{
  border-radius: 0px;
  background-color: #FFFFFF;
}
.ag-card-1{
  background: #FFFFFF;
  border: 1px solid $silver-iron-color;
  box-sizing: border-box;
  border-radius: 8px;
}

////////////////////////////////
///  Alerts
////////////////////////////////
@mixin defaultAlert($border, $background, $textColor){
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 7px 6px;
  border-radius: 4px;
  border: 1px solid $border;
  background: $background;
  color: $textColor;
  p{
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}

.ag-alert-danger{
  @include defaultAlert($alert-danger-border, $alert-danger-bg, $alert-danger-text-color);
}


.modal-header-title{
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  color: $downriver;
}
.modal-header-description{
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  color: $downriver;
}


////////////////////////////////
///  Others
////////////////////////////////
@mixin defaultBadge(){
  background-color: $color-primary;
  color: #FFF;
}
.badge-accent{
  @include defaultBadge();
  padding: 5px 10px;
  border-radius: 12px;
}

////////////////////////////////
///  Others
////////////////////////////////
.no-hover{
  pointer-events: none;
}