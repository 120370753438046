.card p {
  font-size: 12px;
  opacity: .5;
  text-align: right;
  margin-bottom: 2px;
}
.card-title  {
  font-size: 14px;
  color: #0A1747;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.card .goodscore {
  margin-right: 10px;
  color: #5A9E6F;
}
.card .badscore {
  margin-right: 10px;
  color: #D90404;
}
.card .mediumscore {
  margin-right: 10px;
  color: #FA7F50;
}

.card .card-score {
font-size: 15px;
line-height: 0.1;
text-align: right;
}

.card {
  border-radius: 5px;
  box-shadow: 0 0 5px rgb(230,230,230);
  text-align: left;
  padding: 20px;
  border: 1px solid rgb(230,230,230);
  min-height: 109px;
  height: 117px;
  }

.declarationType1 {
  border-left-color: #BD4089;
  border-left-width: thick;
  }

.declarationType2 {
    border-left-color: #BD4089;
    border-left-width: thick;
    }

.declarationType3 {
    border-left-color: #7D80DA;
    border-left-width: thick;
    }

.declarationType4 {
    border-left-color: #FFBA08;
    border-left-width: thick;
        }

.card .btn {
    display: none;
    }

    .card:hover .btn {
      display: inline;
      margin: 0px;
  }

  .card:hover {
    background: rgba(237, 237, 237, 0.5)
}