// Variables
$primary-color: #0c1c46;
$secondary-color: #506ff2;
$color-tertiary:#3805F2;

$page-background: #E5E5E5;
$page-background-white-lilac: #F5F7FB;

$color-dark-pink:#D32D79; 

$downriver:#0A1747;
$blue-color: #3805F2;
$silver-iron-color: #DBDDE1;

$silver-arthens-gray: #F2F4F7;
$solid-blue: #0275D8;

$success-green:#5A9E6F;

$dark-green:#008037;

/// Alert Colors
$alert-danger-bg: #F7D9D9;
$alert-danger-border: #F1BCBC;
$alert-danger-text-color: #EA3E3E;
