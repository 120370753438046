.chip {
  display: flex;
  align-items: center;
  padding: 0 12px;
  height: 22px;
  font-size: 10px;
  line-height: 18px;
  border-radius: 25px;
  background-color: #D9EDF7;
  color: #31708F;
}


.closebtn {
  padding-left: 10px;
  color: #888;
  font-weight: bold;
  float: right;
  font-size: 20px;
  cursor: pointer;
}

.closebtn:hover {
  color: #000;
}
