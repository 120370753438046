.search-form-control {
  position: relative;
  width: 500px;
  display: flex;
}

.search-form-control .btn {
  position: absolute;
  top: 3px;
  bottom: 16px;
  right: 0px;
  background: #3805F2;
}

.search-form-control .form-control {
  height: 1.5rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  border: 1px solid #E7E7E7;
  padding-left: 12px;
  background: white;
}

.form-control {
  font-size: 12px;
}


.search-form-control .form-control:focus {
  border: 1px solid #3805F2;
  outline: none !important;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
}

.search-engine-category {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.notification {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  padding: 8px 16px;
  color: #0A1747;
  font-size: 12px;
  cursor: pointer;
}

.notification .notification-content {
  flex-grow: 1;
  padding: 0 24px;
  width: 50%;
}

.notification p {
  margin: 0;
  line-height: 1.4;
}

h6 {
  color: #0A1747;
}

.notification:hover {
  font-weight: bolder;
}
