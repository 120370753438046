/* Style the button that is used to open and close the collapsible content */
.collapsible {
  background-color: white;
  border-bottom: red;
  color: #0A1747;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .collapsible:hover {
  background-color: white;
}

/* Style the collapsible content. Note: hidden by default */
.content {
  padding: 0 18px;
  display: none;
  overflow: hidden;
  background-color: #F2F4F7;
  color: #0A1747;
}

.family-name {
  font-size: 14px;
}

li {
  list-style-type: none;
  font-size: 12px;
}

li li {
  list-style-type: none;
}

.chip-filter {
  text-align: center;
  margin-bottom: 18px;
  display: flex;
  flex-wrap: wrap;
}

.declarationTypeChip1 {
  color: white;
  background: #BD4089 !important;
}

.declarationTypeChip2 {
  color: white;
  background: #BD4089 !important;
}
.declarationTypeChip3 {
  color: white;
  background: #7D80DA !important;
}
.declarationTypeChip4 {
  color: white;
  background: #FFBA08 !important;
}

.hovertext {
  position: relative;

}

.hovertext:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 140px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  transition: opacity 1s ease-in-out;

  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
}

.hovertext:hover:before {
  opacity: 1;
  visibility: visible;
}
ul{
  /* padding-left: 40px; */
}