// Variables
@import url('https://fonts.googleapis.com/css?family=Raleway:300|Libre+Franklin|Lato');
@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import './variables.scss';
@import './assets/Libre-Franklin-Lato.css';
@import './assets/family-poppins-swap.css';
:root {
  --primary-color: #0c1c46;
  --secondary-color: #506ff2;
  --third-color: #6F74E8;
}
* {
  font-family: 'Lato', sans-serif;
}

@font-face {
  font-family: 'CenturySchoolbook-Bold';
  src: url('./assets/fonts/CenturySchoolbook-Bold.ttf');
}
@font-face {
  font-family: 'CenturySchoolbook';
  src: url('./assets/fonts/CenturySchoolbook-Regular.ttf');
}
@font-face {
  font-family: 'Garamond';
  src: url('./assets/fonts/Garamond-Regular.ttf');
}

@font-face {
  font-family: 'Garamond-Bold';
  src: url('./assets/fonts/Garamond-Bold.ttf');
}

@font-face {
  font-family: 'TimesNewRoman';
  src: url('./assets/fonts/TimesNewRoman-Regular.ttf');
}

@font-face {
  font-family: 'TimesNewRoman-Bold';
  src: url('./assets/fonts/TimesNewRoman-bold.ttf');
}

@font-face {
  font-family: 'OpenSans';
  src: url('./assets/fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'OpenSans-Bold';
  src: url('./assets/fonts/OpenSans-Bold.ttf');
}

//Loading Page
body {
  margin: 0px;
}
.ql-snow .ql-editor span.ql-link {
    color: inherit !important;
    text-decoration: none !important;
}
.relative {
  position: relative;
}
////////////////
/// Mixing
////////////////
/// 
$color-primary-text: #FFFFFF;
$primary-btn-border-radius: 6px;
$btn-icon-margin-right: 5px;
$color-primary: #0A1747;
$color-secondary-focus: #DFE4F6;
$color-primary-disabled-txt: #B6BACB;
$color-primary-focus: #1F306E;
$color-primary-disabled: #E1E4EE;
$color-secondary-disabled-txt: #B6BACB;

$btn-icon-size: 17px;
@mixin btnsSharedMixing(){
  padding: 0px;
  outline: none;
  color: $color-primary-text;
  border-radius: $primary-btn-border-radius;
  transition: all 0.3s linear;
  font-size: 14px;
  i{
    font-size: $btn-icon-size;
    margin-right: $btn-icon-margin-right;
  }
}
@mixin tertiaryBtnMixing(){
  @include btnsSharedMixing();
  background-color: transparent;
  border: transparent;
  color: $color-primary;
  /// Hover
  &:hover{
    box-shadow: -2px 3px 6px #0A174733;
  }
  /// Focus
  &:focus{
    background-color: $color-secondary-focus;
  }
  /// Disabled
  &:disabled{
    color: $color-primary-disabled-txt;
    border-color: $color-primary-disabled-txt;
  }
}
@mixin primaryBtnMixing(){
  @include btnsSharedMixing();
  background-color: $color-primary !important;
  border: none;
  /// Hover
  &:hover{
    box-shadow: -2px 3px 6px #0A174733;
  }
  /// Focus
  &:focus{
    background-color: $color-primary-focus;
  }
  /// Disabled
  &:disabled{
    background-color: $color-primary-disabled;
    color: $color-secondary-disabled-txt;
  }
}

.main-header {
  position: relative;
  width: 100%;
  display: block;
  margin-right: auto;
  margin-left: auto;
  border-bottom: 1px solid #D8D8D8;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1) !important;
}

.logo-holder {
  padding: 0.9rem 3.2rem;
  border-right: 1px solid #D8D8D8;
}

.menu-contain {
  position: absolute;
  text-align: right;
  height: 9rem;
  padding-right: 2rem;
  top: 2rem;
  right: 0;
}

.floating {
  float: left;
  -webkit-animation-name: Floatingx;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-name: Floating;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;

}

.localisation-input {
  .edit-span {
    text-align: left !important;
  }
}

.p-sidebar .p-sidebar-header{
  padding: 0 !important;
  display: none !important;
}

.p-sidebar .p-sidebar-content{
  padding: 0px !important;
}
.p-component-overlay{
  // display: none !important;
  .p-confirm-dialog{
    width: 40%;
    .p-dialog-footer{
      text-align: center;
      button{
        padding: 9px 24px 10px 24px !important;
      }
      button.p-confirm-dialog-reject{
        @include tertiaryBtnMixing();
        margin-right: 15px;
        border: solid 1px $color-primary;
      }
      button.p-confirm-dialog-accept{
        @include primaryBtnMixing();
      }
    }
  }
}

@-webkit-keyframes Floatingx {
  from {
    -webkit-transform: translate(0, 0px);
  }

  65% {
    -webkit-transform: translate(0, 15px);
  }

  to {
    -webkit-transform: translate(0, -0px);
  }
}

@-moz-keyframes Floating {
  from {
    -moz-transform: translate(0, 0px);
  }

  65% {
    -moz-transform: translate(0, 15px);
  }

  to {
    -moz-transform: translate(0, -0px);
  }
}

.pointer {
  cursor: pointer;
}

.scaling {
  float: left;
  -webkit-animation-name: scalex;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-name: scale;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: ease-in-out;
}

.ui-widget-overlay {
  background: rgba(102, 102, 102, 0.4) !important;
  opacity: 1 !important;
}

@-webkit-keyframes scalex {
  from {
    -webkit-transform: scale(0.9);
  }

  65% {
    -webkit-transform: scale(1.0);
  }

  to {
    -webkit-transform: scale(0.9);
  }
}

@-moz-keyframes scale {
  from {
    -moz-transform: scale(0.9);
  }

  65% {
    -moz-transform: scale(1.0);
  }

  to {
    -moz-transform: scale(0.9);
  }
}

.big-letter {
  font-size: 30px !important;
}

.p-sidebar-bottom {
  height: 90% !important;
  padding: 0px !important;
  overflow: scroll;
}
.p-treenode-selectable{
  // width: 0px !important;
  background-color: white;
  outline: none;
  outline-color: white;color: #585858;

}
.p-tree .p-tree-container .p-treenode .p-treenode-content{
  padding: 0rem !important;
  padding-bottom: 1rem !important;
}

.p-sidebar-right {
  width: 80em !important;
  // width: 40em !important;
  border: 1px solid #D5D5D5;
  background-color: #f1f3f4 !important;
  height: 100vh;
  overflow-y: auto;
}

.p-tree .p-tree-container .p-treenode:focus > .p-treenode-content {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}
.revit-sidebar {

  .p-sidebar {
    width: 100% !important;
  }
}
@media screen and (max-width: 449px) {
  .revit-sidebar {
    .p-sidebar {
      width: 50% !important;
    }
  }
}

//Global
.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.center {
  text-align: center;
}

.center-image {
  display: block;
  margin: auto;
}

.full-width {
  width: 100%;
}

.container-fluid {
  padding: 0px !important;
}

.required-star:after {
  color: #e32;
  content: ' *';
  display: inline;
}

.form-control:focus {
  border-color: $primary-color !important;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(22, 240, 169, .075);
}

.form-control {
  border-radius: 0px !important;
}

.input-group-lg {
  width: 100%
}

input[type=text]:focus:not([readonly]),
input[type=password]:focus:not([readonly]),
input[type=email]:focus:not([readonly]),
input[type=url]:focus:not([readonly]),
input[type=time]:focus:not([readonly]),
input[type=date]:focus:not([readonly]),
input[type=datetime-local]:focus:not([readonly]),
input[type=tel]:focus:not([readonly]),
input[type=number]:focus:not([readonly]),
input[type=search-md]:focus:not([readonly]),
input[type=search]:focus:not([readonly]),
textarea.md-textarea:focus:not([readonly]) {
  border-bottom: 1px solid $secondary-color !important;
  box-shadow: 0 1px 0 0 $secondary-color !important;
}

.input-group-addon {
  border-radius: 0px !important;
  background-color: white !important;
}

.secondary {
  color: $secondary-color;
}

.primary {
  color: $primary-color;
}

.text-danger {
  -webkit-transition: all linear 0.5s;
  transition: all linear 0.5s;
}

.text-danger {
  color: #a94442;
  padding-left: 15px;
}

textarea.md-textarea {
  overflow-y: scroll !important;
}

.resized .phone-indicator {
  width: 200px !important;
  padding-bottom: 10px !important;
  padding-top: 13px;
}

.new-a {
  color: black;
  text-decoration: underline;

  &:hover {
    color: #72e5ab;
    font-weight: 600;
  }
}

//Client form
.question-label {
  font-size: 21px;
  margin-bottom: 30px;
}

.thick-hr {
  border-top: 2px solid #eee;
}

//Responsive
@media screen and (max-width: 580px) {
  .device {
    display: none !important;
  }

  .desk {
    display: block;
  }
}

@media screen and (min-width: 580px) {
  .desk {
    display: none !important;
  }

  .device {
    display: block;
  }
}

@media screen and (min-width: 900px) {
  .tablet {
    display: block;
  }

  .no-tablet {
    display: none !important;
  }
}

@media screen and (max-width: 900px) {
  .tablet {
    display: none !important;
  }

  .no-tablet {
    display: block;
  }
}

//Validation Button
.w-300-w {
  width: 300px;
}

.btn-primary-color {
  text-align: center;
  line-height: 50px;
  height: 50px;
  background: $primary-color;
  display: block;
  margin: 20px auto;
  border: 0px;
  color: #fff;
  font-size: 16px;
  font-weight: 800;
  border-radius: 5px;
  -webkit-transition-duration: 1s;
  -webkit-transition-timing-function: linear;
  padding: 0px 50px;
}

.btn-primary-color:hover {
  transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1, 1.1);
  /* Safari */
}

.btn-primary-color:focus {
  outline: none;
}

.btn-success-color {
  text-align: center;
  line-height: 50px;
  height: 50px;
  background: #4BB543;
  display: block;
  margin: 20px auto;
  border: 0px;
  color: #fff;
  font-size: 16px;
  font-weight: 800;
  border-radius: 5px;
  -webkit-transition-duration: 1s;
  -webkit-transition-timing-function: linear;
  padding: 0px 50px;
}

.btn-success-color:hover {
  transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1, 1.1);
  /* Safari */
}

.btn-success-color:focus {
  outline: none;
}

.btn-danger-color {
  text-align: center;
  line-height: 50px;
  height: 50px;
  background: #bb2124;
  display: block;
  margin: 20px auto;
  border: 0px;
  color: #fff;
  font-size: 16px;
  font-weight: 800;
  border-radius: 5px;
  -webkit-transition-duration: 1s;
  -webkit-transition-timing-function: linear;
  padding: 0px 50px;
}

.btn-danger-color:hover {
  transform: scale(1.1, 1.1);
  -ms-transform: scale(1.1, 1.1);
  /* IE 9 */
  -webkit-transform: scale(1.1, 1.1);
  /* Safari */
}

.btn-danger-color:focus {
  outline: none;
}

.btn-secondary-color {
  text-align: center;
  line-height: 50px;
  height: 50px;
  width: 200px;
  background: $secondary-color;
  display: block;
  margin: 20px auto;
  border: 0px;
  color: #fff;
  box-shadow: 0 0 1px #ccc;
  -webkit-transition-duration: 1s;
  -webkit-transition-timing-function: linear;
  box-shadow: 0px 0 0 $secondary-color inset;
}


.btn-secondary-color:hover {
  // box-shadow:0 200px 0 #ffde9a inset;
}

.btn-secondary-color:focus {
  outline: none;
}

.btn-primary-border {
  text-align: center;
  line-height: 50px;
  //width: 200px;
  padding: 0px 15px;
  background: white;
  display: block;
  margin: 20px auto;
  border: 0px;
  border-radius: 5px;
  color: $primary-color;
  box-shadow: 0 0 1px #ccc;
  -webkit-transition-duration: 0.1s;
  -webkit-transition-timing-function: linear;
  box-shadow: 0px 0 0 $primary-color inset;
  border: 1px solid $primary-color;
}

.btn-primary-border:hover {
  box-shadow: inset 0px 0px 0px 2px $primary-color;
}

.btn-primary-border:focus {
  outline: none;
}

.btn-secondary-border {
  text-align: center;
  line-height: 50px;
  width: 200px;
  background: white;
  display: block;
  margin: 20px auto;
  border: 0px;
  color: $secondary-color;
  box-shadow: 0 0 1px #ccc;
  -webkit-transition-duration: 0.1s;
  -webkit-transition-timing-function: linear;
  box-shadow: 0px 0 0 $secondary-color inset;
  border: 1px solid $secondary-color;
}

.btn-secondary-border:hover {
  box-shadow: inset 0px 0px 0px 2px $secondary-color;
}

.btn-secondary-border:focus {
  outline: none;
}

.btn-yes {
  text-align: center;
  background: white;
  display: block;
  margin: 5px auto;
  border: 0px;
  color: #00b77d;
  box-shadow: 0 0 1px #ccc;
  box-shadow: 0px 0 0 #00b77d inset;
  border: 1px solid #00b77d;
  padding: 10px;
}

.btn-yes:focus {
  outline: none;
}

.btn-yes:hover {
  background-color: rgba(48, 227, 170, 0.1)
}

.btn-yes-filled {
  text-align: center;
  background: white;
  display: block;
  margin: 5px auto;
  border: 0px;
  background-color: #00b77d;
  color: white;
  box-shadow: 0 0 1px #ccc;
  box-shadow: 0px 0 0 #00b77d inset;
  border: 1px solid #00b77d;
  padding: 10px;
}

.btn-yes-filled:focus {
  outline: none;
}

.btn-yes-filled:hover {
  background-color: rgba(0, 183, 125, 0.71);
}

.btn-no {
  text-align: center;
  color: #e91e63;
  border: solid 1px #e91e63;
  display: block;
  margin: 5px auto;
  background-color: white;
  box-shadow: 0 0 1px #ccc;
  box-shadow: 0px 0 0 #ffde9a inset;
  padding: 10px;
}

.btn-no:focus {
  outline: none;
}

.btn-no:hover {
  background-color: rgba(233, 32, 99, 0.1)
}

.btn-gray-border {
  text-align: center;
  color: #9E9E9E;
  border: solid 1px #9E9E9E;
  display: block;
  margin: 5px auto;
  background-color: white;
  box-shadow: 0 0 1px #ccc;
  box-shadow: 0px 0 0 #ffde9a inset;
  padding: 10px;
}

.btn-gray-border:focus {
  outline: none;
}

.btn-gray-border:hover {
  background-color: rgba(0, 0, 0, 0.10)
}

.btn-gray-fill {
  text-align: center;
  background-color: #c2c2c2;
  border: solid 1px #c2c2c2;
  display: block;
  margin: 5px auto;
  color: white;
  box-shadow: 0 0 1px #ccc;
  box-shadow: 0px 0 0 #ffde9a inset;
  padding: 10px;
}

.btn-gray-fill:focus {
  outline: none;
}

.btn-gray-fill:hover {
  background-color: rgba(0, 0, 0, 0.10)
}

//Tabs
.nav-pills>li.active>a {
  background-color: $primary-color !important;
  color: white !important;
  border-radius: 30px;
}

.nav-pills>li>a {
  color: $primary-color !important;
  border-radius: 30px;
}



.uploader-secondary-color :focus {
  outline: none;
}



//Select 2 NG Prime
.wid100 {
  width: 100% !important;
}

.ui-autocomplete-dropdown {
  position: absolute !important;
  top: 0px;
  right: 0px;
}

.ui-calendar {
  width: 100%
}

.artisan-archi-calendar {
  .ui-datepicker.ui-widget {
    left: -100px !important;
  }
}

.ui-autocomplete-input {
  font-size: 18px !important;
  padding-bottom: 15px !important;
  padding-left: 15px !important;
}

.fa-caret-down:before {
  content: "\F0D7";
}

//Multiselect NG Prime
.multiselect-item-ng-options-panel-border {
  border: 1px solid rgb(99, 228, 170) !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.multiselect-item-ng-options-panel-border .ui-multiselect-items-wrapper {
  padding: 0px !important;
}

.multiselect-item-ng-options-panel-border li {
  margin: 0px !important;
  border-radius: 0px !important;
  padding: 3px 5px !important;

  label {
    font-size: 14px !important;
  }

  &:hover {
    background-color: rgb(99, 228, 170) !important;
  }
}

.multiselect-item-ng-options-panel-hover-background {
  min-width: 160px !important;
  margin: 5px 5px !important;
  background: transparent !important;
  border-bottom: 1px solid rgb(99, 228, 170) !important;
}

.multiselect-item-ng-options-panel-hover-background label {
  margin: 1px !important;
  padding: 0px 25px 0px 0px !important;
  font-family: "Roboto", sans-serif !important;
  color: black !important;
  font-weight: 100 !important;
  background-color: transparent !important;
  text-align: left;
}

.multiselect-item-ng-options-panel-hover-background .ui-multiselect-trigger {
  background-color: transparent !important;
}

/*Image Thumb Nail for Upload Care*/
.thumb-nail {
  margin: 10px auto;
  display: block;
  height: 100px;
  width: 150px;
  object-fit: cover;
}

//Mask Inout
p-inputmask.form-control {
  padding: 0px !important;
  border: none;
  box-shadow: none;
}

p-inputmask.form-control>input {
  padding: 10px 16px;
  font-size: 18px;
}

.mask-phone {
  margin: 16px !important;
}

//rating input
.ui-rating {
  // text-align: center;
}

.ui-rating .fa-star-o {
  color: $primary-color;
  font-size: 25px;
}

.ui-rating .fa-star {
  color: $primary-color;
  font-size: 25px;
}

//switch
.pricing-switch {
  margin-bottom: -5px;
}

//Check input
.all-width {
  .ui-widget {
    width: 100% !important;
  }
}

.full-width-check {
  width: 100% !important;
}

.inline-check {
  margin: 0px 0px;
  padding: 0px 10px;
  display: inline-block;
  overflow: hidden
}

//Check CGU
.ui-button-text-only .ui-button-text {
  padding: .75em 1em !important;
}

.select-projets-view {
  float: right;
  margin: 20px 20px 10px;

  .ui-button-text-only .ui-button-text {
    padding: 1em 1em 1em 2.1em !important;
    font-size: 16px;
  }

  .ui-button.ui-state-active {
    background: #d6d6d6 !important;
  }

  .ui-button.ui-state-focus {
    box-shadow: 0px 0px 5px #d6d6d6 !important;
  }
}

.ui-button-text:focus {
  border: none;
  outline: none;
}

//Loading Modale
.modal-spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.15);
  Z-INDEX: 1;
}

.spinner-center {
  position: relative;
  top: 35%;
}

/******  MODAL ******/
.modal-container {
  padding: 30px;
  width: 100% !important;
  background-color: #F7F7F7;
  margin: 0 auto;
  border-radius: 2px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  font-family: sans-serif;
}

.form-container {
  width: 70%;
  display: block;
  margin: auto;
  margin-bottom: 50px;
}

.modal-container h1 {
  text-align: center;
  font-size: 2.2em;
  font-family: sans-serif;
  font-weight: 200;
  color: $primary-color;
}

.modal-dialog {
  margin: auto;
  margin-top: 5%;
  width: 80%;
}

@media screen and (max-width: 480px) {
  .modal-dialog {
    width: 100%;
  }
}

//Maps
.amcharts-chart-div>a {
  display: none !important;
}

//Image Radio Button
.cc-selector input {
  margin: 0;
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.cc-selector input:active+.drinkcard-cc {
  opacity: .9;
}

.cc-selector input:checked+.drinkcard-cc {
  -webkit-filter: none;
  -moz-filter: none;
  filter: none;
  border: 2px solid $primary-color
}

.drinkcard-cc {
  width: 100%;
  padding-bottom: 100%;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  // -webkit-transition: all 100ms ease-in;
  //    -moz-transition: all 100ms ease-in;
  //         transition: all 100ms ease-in;
  // -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
  //    -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
  //         filter: brightness(1.2) grayscale(.5) opacity(.9);
  border: 2px solid rgba(255, 255, 255, 0);
}

.drinkcard-cc:hover {
  -webkit-filter: brightness(1.2) grayscale(.5) opacity(.9);
  -moz-filter: brightness(1.2) grayscale(.5) opacity(.9);
  filter: brightness(1.2) grayscale(.5) opacity(.9);
}

/* Extras */
a:visited {
  color: #888
}

a {
  color: #444;
  text-decoration: none;
}

p {
  margin-bottom: .3em;
}

.oui-version {
  color: white !important;
  background: #20a077 !important;
}


//CLient Form
.all-gray {
  background-color: #f5f7fb;
  min-height: 100vh;
}

.floating-card {
  background-color: white;
  padding: 30px;
  margin-top: 25px;
  margin-bottom: 25px;
  // box-shadow: 0 0 51px 0 rgba(0,0,0,.08), 0 6px 18px 0 rgba(0,0,0,.05);
  border: 1px solid #dbdde1;
  border-radius: 4px;

  h1 {
    font-weight: 700;
    font-size: 22px;
    margin-left: 25px;
  }

  h2 {
    color: black;
    font-weight: 700;
    font-size: 17px;
    margin-left: 10px;
  }

  .question-label {
    margin-bottom: 20px;
  }
}

//ToolTip

.my-tooltip {}

.my-tooltip .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.ui-tooltip-text.ui-shadow.ui-corner-all {
  background: #0A1747;
  border-radius: 8px;
  padding: 3px 6px;
}

.my-tooltip:hover .tooltiptext {
  visibility: visible;
}

.hide-tool-bar {
  visibility: hidden;
}

.click-it {
  cursor: pointer;
}

//************ Done Animation Mark ****************/
.check-mark-animation {
  .check_mark {
    width: 80px;
    height: 130px;
    margin: 0 auto;
  }

  button {
    cursor: pointer;
    margin-left: 15px;
  }

  .hide {
    display: none;
  }

  .sa-icon {
    width: 80px;
    height: 80px;
    border: 4px solid gray;
    -webkit-border-radius: 40px;
    border-radius: 40px;
    border-radius: 50%;
    margin: 20px auto;
    padding: 0;
    position: relative;
    box-sizing: content-box;
  }

  .sa-icon.sa-success {
    border-color: #4CAF50;
  }

  .sa-icon.sa-success::before,
  .sa-icon.sa-success::after {
    content: '';
    -webkit-border-radius: 40px;
    border-radius: 40px;
    border-radius: 50%;
    position: absolute;
    width: 60px;
    height: 120px;
    background: #f7f7f7;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .sa-white::before,
  .sa-white::after {
    background: white !important;
  }

  .sa-white-back {
    background: white !important;
  }

  .sa-icon.sa-success::before {
    -webkit-border-radius: 120px 0 0 120px;
    border-radius: 120px 0 0 120px;
    top: -7px;
    left: -33px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 60px 60px;
    transform-origin: 60px 60px;
  }

  .sa-icon.sa-success::after {
    -webkit-border-radius: 0 120px 120px 0;
    border-radius: 0 120px 120px 0;
    top: -11px;
    left: 30px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 0px 60px;
    transform-origin: 0px 60px;
  }

  .sa-icon.sa-success .sa-placeholder {
    width: 80px;
    height: 80px;
    border: 4px solid rgba(76, 175, 80, .5);
    -webkit-border-radius: 40px;
    border-radius: 40px;
    border-radius: 50%;
    box-sizing: content-box;
    position: absolute;
    left: -4px;
    top: -4px;
    z-index: 2;
  }

  .sa-icon.sa-success .sa-fix {
    width: 5px;
    height: 90px;
    background-color: #f7f7f7;
    position: absolute;
    left: 28px;
    top: 8px;
    z-index: 1;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .sa-icon.sa-success.animate::after {
    -webkit-animation: rotatePlaceholder 4.25s ease-in;
    animation: rotatePlaceholder 4.25s ease-in;
  }

  .sa-icon.sa-success {
    border-color: transparent\9;
  }

  .sa-icon.sa-success .sa-line.sa-tip {
    -ms-transform: rotate(45deg) \9;
  }

  .sa-icon.sa-success .sa-line.sa-long {
    -ms-transform: rotate(-45deg) \9;
  }

  .animateSuccessTip {
    -webkit-animation: animateSuccessTip 0.75s;
    animation: animateSuccessTip 0.75s;
  }

  .animateSuccessLong {
    -webkit-animation: animateSuccessLong 0.75s;
    animation: animateSuccessLong 0.75s;
  }

  @-webkit-keyframes animateSuccessLong {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }

    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }

    84% {
      width: 55px;
      right: 0px;
      top: 35px;
    }

    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }

  @-webkit-keyframes animateSuccessTip {
    0% {
      width: 0;
      left: 1px;
      top: 19px;
    }

    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }

    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }

    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }

    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }

  @keyframes animateSuccessTip {
    0% {
      width: 0;
      left: 1px;
      top: 19px;
    }

    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }

    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }

    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }

    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }

  @keyframes animateSuccessLong {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }

    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }

    84% {
      width: 55px;
      right: 0px;
      top: 35px;
    }

    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }

  .sa-icon.sa-success .sa-line {
    height: 5px;
    background-color: #4CAF50;
    display: block;
    border-radius: 2px;
    position: absolute;
    z-index: 2;
  }

  .sa-icon.sa-success .sa-line.sa-tip {
    width: 25px;
    left: 14px;
    top: 46px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .sa-icon.sa-success .sa-line.sa-long {
    width: 47px;
    right: 8px;
    top: 38px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  @-webkit-keyframes rotatePlaceholder {
    0% {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    5% {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    12% {
      transform: rotate(-405deg);
      -webkit-transform: rotate(-405deg);
    }

    100% {
      transform: rotate(-405deg);
      -webkit-transform: rotate(-405deg);
    }
  }

  @keyframes rotatePlaceholder {
    0% {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    5% {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }

    12% {
      transform: rotate(-405deg);
      -webkit-transform: rotate(-405deg);
    }

    100% {
      transform: rotate(-405deg);
      -webkit-transform: rotate(-405deg);
    }
  }
}

#carnet {
  .ui-multiselect {
    width: 100% !important;
    border-bottom: 1px solid #ccc;
  }

  .ui-multiselect-label {
    color: gray;
    padding: 10px 0px;
  }

  .ui-multiselect:not(.ui-state-disabled):hover {
    background: white !important;
  }

  .ui-multiselect-label-container {
    padding: 0px 10px;
  }
}

/* Tooltip  */
.tooltip-el {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip-el .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-el:hover .tooltiptext {
  visibility: visible;
}

.accept-confirm-modal {
  background-color: $primary-color !important;
  color: white !important;
}

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

//GO Back button

.b_round,
.s_round {
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 50%;
  background-color: $primary-color;
  transition: all 0.2s linear;
}

.s_round {
  left: 10px;
  top: 10px;
}


.b_round {
  opacity: 0;
  background-color: $primary-color;
}

.b_round:hover {
  transform: scale(1.37);
  opacity: 0.4;
}

.b_round_back:hover {
  background-color: #F60044;
}

.s_round_click {
  transform: scale(1.7);
}

.s_round_back {
  background-color: #C30C3D;
}

.s_arrow {
  width: 25px;
  height: 25px;
  display: block;
  margin: 13px 10px;
  background-size: cover !important;
  background-image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDQ3Ny4xNzUgNDc3LjE3NSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDc3LjE3NSA0NzcuMTc1OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgd2lkdGg9IjUxMnB4IiBoZWlnaHQ9IjUxMnB4Ij4KPGc+Cgk8cGF0aCBkPSJNMTQ1LjE4OCwyMzguNTc1bDIxNS41LTIxNS41YzUuMy01LjMsNS4zLTEzLjgsMC0xOS4xcy0xMy44LTUuMy0xOS4xLDBsLTIyNS4xLDIyNS4xYy01LjMsNS4zLTUuMywxMy44LDAsMTkuMWwyMjUuMSwyMjUgICBjMi42LDIuNiw2LjEsNCw5LjUsNHM2LjktMS4zLDkuNS00YzUuMy01LjMsNS4zLTEzLjgsMC0xOS4xTDE0NS4xODgsMjM4LjU3NXoiIGZpbGw9IiNGRkZGRkYiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K) // background-image: url('https://img-fotki.yandex.ru/get/194549/29644339.5/0_d6c60_1d7815f0_orig');
    // background-color: transparent;
    // transition: all 0.35s linear;
    // text-align: center;
    // color: white;
    // font-size: 26px;
    // line-height: 2;
}

.s_arrow_rotate {
  transform: rotate(-180deg);
}

//cctp-line
.cctp-content-html {
  li {
    list-style-type: disc !important;
  }

  ul {
    margin-left: 40px;
  }
}

//Check Done

.success-checkmark {
  width: 80px;
  height: 115px;
  margin: 0 auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4CAF50;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: '';
      height: 100px;
      position: absolute;
      background: #FFFFFF;
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: #4CAF50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, .5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #FFFFFF;
    }
  }
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }

  5% {
    transform: rotate(-45deg);
  }

  12% {
    transform: rotate(-405deg);
  }

  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }

  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }

  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }

  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }

  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }

  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

.ui-dialog.ui-widget .ui-dialog-content {
  overflow-x: hidden;
}

//CSTB

.cstb-content {
  label {
    font-weight: 300;
  }

  textarea {
    height: auto;
    width: auto;
  }

  h2 {
    margin: 0px !important;
  }

  .listcheckbox {
    padding-left: 3rem;
  }

  .licheckbox {
    position: relative;

    input {
      margin-left: -1rem;
      float: left;
    }

    label {
      display: inline-block;
      padding-left: 4px;
      width: 100%;
      font-weight: inherit;
    }
  }

  .liradio {
    input {
      margin-left: -1rem;
      float: left;
    }

    label {
      display: inline-block;
      padding-left: 4px;
      width: 100%;
      font-weight: inherit;
    }
  }
}

//SIde BAr
.ui-sidebar-right {
  padding: 0px !important;
}

h1.uis-h-title {
  margin: 20px 0px;
}


//CCTP
.cctp-field {
  ul {
    margin-left: 20px;

    li {
      list-style-type: disc;
    }
  }
}

#cctp {
  .uis-h-title {
    font-size: 12pt;
    color: black;
    margin-top: 0px !important;
    margin-bottom: 12px !important;
  }

  .titlesec {
    font-size: 11pt !important;
    font-weight: bold;
  }

  content {
    display: block;
    padding-left: 15px;
  }

  .liradio {
    padding-left: 3rem;
  }

  div.ul-elem {
    padding-left: 3rem;
  }

  .ul-elem {
    padding-bottom: 1rem;
  }

}

/// Global Styles
.pill-nav {
  background-color: #D9EDF7;
  color: #31708F;
  border-radius: 15px;
  padding: 6px 12px;
  font-size: 12px;
  font-weight: bold;
}

.pill-nav-circle {
  color: #FFFFFF;
  // background-color: #C4C4C4;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
}

.custom-tooltip {
  // background-color: #414141;
  background-color: $downriver;
  border-radius: 5px;
  padding: 5px 10px;
  z-index: 3;
  position: absolute;
  top: 0px;
  left: -65px;
  opacity: 0 !important;
  transition: all 0.3s linear;

  &:after {
    content: "";
    width: 10px;
    height: 10px;
    background-color: $downriver;
    display: inline-block;
    position: absolute;
    top: 7px;
    right: -5px;
    transform: rotate(45deg);
  }
}

.editor {
  height: 100% !important;
  // width: 100% !important;
}

.pill-nav-success {
  background-color: #93E5AC;
}

.pill-nav-fail {
  background-color: #E1E2E5;
}

.margin-right-5 {
  margin-right: 5px;
}

.text-center {
  text-align: center;
}

.icons_position {
  display: flex;
  justify-content: space-between;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.simple-notification-wrapper {
  z-index: 100000 !important;
}

.no-resize {
  resize: none;
}

.no-margin {
  margin: 0px;
}

@media screen and (max-width: 640px) {
  .hide-min {
    display: none;
  }
}

.ui-inputswitch.ui-widget {
  margin: 10px;
  float: right;

  .ui-inputswitch-slider {
    background-color: #DBDDE1;

    &:before {
      background-color: #FFFFFF;

    }
  }
}

.ui-inputswitch.ui-inputswitch-checked {
  margin: 10px;
  float: right;

  .ui-inputswitch-slider {
    background-color: $primary-color;

    &:before {

      background-color: #FFFFFF;
    }
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


.p-fileupload-choose {
  input[type="file"] {
    display: none;
  }
}


p-autocomplete, .p-autocomplete{
  width: 100% !important;
}


.p-checkbox .p-checkbox-box.p-highlight {
  border-color: rgba(56, 5, 242, 1) !important;
  background: rgba(56, 5, 242, 1) !important;
  height: 17px ;
  width: 17px ;
  border-radius: 4px ;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
  height: 57px !important;
}
.p-checkbox .p-checkbox-box{
  width: 17px !important;
  height: 17px !important;
}

