/* p {
  font-size: 12px;
}

label {
  font-size: 12px;
} */

::placeholder {
  font-size: 12px;
}

.btn-form .btn-flat {
  text-align: center;
  background: #0A1747;
}

.btn-form {
  text-align: center;
}

.pop-up {
  border: 0.5px solid rgba(0, 0, 0, 0.2);
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
