.btn-purple {
  color: #3805F2 !important;
  border: 1px solid #3805F2;
  padding: 5px 22px;
  height: 31px;
  border-radius: 4px;
  font-size: 14px;
  opacity: 0.6;
  background: white;
  transition: opacity 0.3s ease;
}


.btn-purple:hover {
  opacity: 1;
  color: #3805F2;
}

.btn-flat {
  color: white !important;
  font-weight: bolder;
  padding: 8px 24px;
  border-radius: 4px;
  background: #1BCB7F;
  font-size: 16px;
  /* transition: 0.3s ease; */
}

.btn-flat:hover {
  /* background: transparent; */
  color: #1EDD88;
  border: 1px solid #1EDD88
}

.btn-forms {
  color: white;
  font-weight: bolder;
  padding: 8px 24px;
  border-radius: 4px;
  background: #0A1747;
  font-size: 14px;
  transition: 0.3s ease;
}

.btn-forms:hover {
  background: transparent;
  color: #0A1747;
  border: 1px solid #0A1747;
}

.btn-cards {
  color: white;
  padding: 8px 24px;
  border-radius: 4px;
  background: #1EDD88;
  font-size: 14px;
  transition: 0.3s ease;
}

.btn-cards:hover {
  font-weight: bold;
  color: white;
  background: #1EDD88;

}

.btn-gradient {
  color: #3805F2;
  font-size: 13px;
  padding: 8px 24px;
  border-radius: 4px;
  text-decoration-line: underline;
  text-align: right;
  background: none;
}

.btn-gradient:hover {
  font-weight: bold;
  color: #3805F2;
}

.btns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 30px;
}

.hoverbtn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top : 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;

}

.hoverbtn .btn-gradient-position{
position: absolute;
bottom: -10px;
right: 0px;
}


.hoverbtncenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top : 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
}

.btn-grey{
  background-color: #F5F7FB;
  color: #A9A9A9;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  border-radius: 40;
  padding: 15px 24px 15px 24px;
}
